import React, { useState } from "react";
import PropTypes from 'prop-types';
import {
  Card,
  CardBody,
  Container,
  Form,
  Label,
  FormGroup,
  FormText,
  FormFeedback,
  Input,
  Button,
  Col,
  Row,
  Media
} from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { ToastContainer, toast } from 'react-toastify';
import { Link, graphql } from 'gatsby';
import Layout from '../components/Layout';
import SideBar from '../components/SideBar';
import Title from '../components/UIComponents/Title';
import LocalizedLink from '../components/LocalizedLink';
import ContactEmailSvg from '../media/contact_email_0.svg';
import axios from 'axios';
import Helmet from 'react-helmet';
import HeadMeta from '../components/HeadMeta';
import TriDymeIcon from '../media/TriDymeIconV1.svg';


// import 'react-toastify/dist/ReactToastify.css';
const {
  BACKEND_API_URL,
  REACT_COMPANY_NAME,
} = process.env;

const resetForm = () => {
  document.getElementById('contact-form').reset();
}
const gotEmail = async (e) => {
  e.preventDefault();
  console.log('BACKEND_API_URL', BACKEND_API_URL)
  console.log('BACKEND_API_URL', {
    firstname: document.getElementById("firstname").value,
    lastname: document.getElementById("lastname").value,
    company: document.getElementById("company").value,
    email: document.getElementById("email").value,
    phone: document.getElementById("phone").value,
    message: document.getElementById("message").value
  })
  await axios.post(`https://backend.tridyme.com/api/contact`, {
    firstname: document.getElementById("firstname").value,
    lastname: document.getElementById("lastname").value,
    company: document.getElementById("company").value,
    email: document.getElementById("email").value,
    phone: document.getElementById("phone").value,
    message: document.getElementById("message").value
  }).then(function (response) {
    console.log(response);
    resetForm();
    toast.success('🚀 Message Sent!', {
      autoClose: 1500
    });
  })
    .catch(function (error) {
      console.log(error);
      toast.error('😞 No access to the server!', {
        autoClose: 1500
      });
    });
}

const metaData = {
  en: {
    title: `TriDyme | Contact Us`,
    pageTitle: "Contact Us",
    description: "Cloud based softwares for Structural, Geotechnic and Hydraulic design at International Standards (Eurocodes, ACI, AISC,...)"
  },
  fr: {
    title: `TriDyme | Contactez-nous`,
    pageTitle: "Contactez-nous",
    description: "Logiciels Cloud pour des calculs de Structure, de Géotechnique et d'Hydraulique aux standards internationaux (Eurocodes, ACI, AISC,...)"
  }
};

const Contact = ({
  pageContext: { locale },
  data
}) => {

  return (
    <Layout locale={locale}>
      <HeadMeta
        locale={locale}
        metaData={metaData}
      />
      <Container className="contactContainer">
        <ToastContainer />
        <Row>
          <Title
            icon={TriDymeIcon}
            title={metaData[locale].pageTitle}
          />
        </Row>
        <hr />
        {/* <Col md={12}>
          <Title
            icon={TriDymeIcon}
            title={`Par téléphone: `}
          />
        </Col>
        <br />
        <Col md={12}>
          <Title
            icon={TriDymeIcon}
            title={`Par email: `}
          />
        </Col> */}
        <Col md={12}>
          <Label className="title">
            <FormattedMessage
              id="contact.information"
              defaultMessage="Do you have a specific challenge? Contact us and let’s work together!"
            />
          </Label>
        </Col>
        <Col md={12}>
          {/* <Label className="title">
            <FormattedMessage
              id="contact.information"
              defaultMessage="Do you have a specific challenge? Contact us and let’s work together!"
            />
          </Label> */}
          <div className="call-box-top">
            <div className="call-phone">
              <strong> <FormattedMessage
                id="contact.phone"
                defaultMessage="Phone"
              />{': '}</strong>
              <a href={`tel:0033785175050`}>
                {`+33 7 85 17 50 50`}
              </a>
            </div>
            <div className="call-email">
              <strong>Email:   </strong>
              <a href={`mailto:contact@tridyme.com`}>
                {`contact@tridyme.com`}
              </a>
            </div>
          </div>
        </Col>
        {/* <Col md={12}>
          <Label className="title">
            <FormattedMessage
              id="contact.information"
              defaultMessage="Do you have a specific challenge? Contact us and let’s work together!"
            />
          </Label>
        </Col> */}
        <Col md={12}>
          <Card className="card">
            <CardBody>
              <Row>
                <Col md={6}>
                  <div >
                    <FormattedMessage id="structure3D_alt">
                      {
                        alt => (
                          <img
                            className="img"
                            src={ContactEmailSvg}
                            alt={alt}
                          />
                        )
                      }
                    </FormattedMessage>
                  </div>
                </Col>
                <Col md={6}>
                  {/* <Form action={`${BACKEND_API_URL}/contact/`} method="POST"> */}
                  <Form id="contact-form" onSubmit={gotEmail} method="post">
                    {/* <Form id="contact-form" method="post" netlify> */}
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <Label className="title">
                            <FormattedMessage
                              id="contact.firstname"
                              defaultMessage="firstname"
                            />
                          </Label>
                          <FormattedMessage
                            id="contact.firstname"
                            defaultMessage="firstname"
                          >
                            {
                              placeholder => (
                                <Input type="text" name="firstname" id="firstname" placeholder={placeholder} required />
                              )
                            }
                          </FormattedMessage>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label className="title">
                            <FormattedMessage
                              id="contact.lastname"
                              defaultMessage="lastname"
                            />
                          </Label>
                          <FormattedMessage
                            id="contact.lastname"
                            defaultMessage="lastname"
                          >
                            {
                              placeholder => (
                                <Input type="text" name="lastname" id="lastname" placeholder={placeholder} required />
                              )
                            }
                          </FormattedMessage>
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup>
                      <Label className="title">
                        <FormattedMessage
                          id="contact.company"
                          defaultMessage="company"
                        />
                      </Label>
                      <FormattedMessage
                        id="contact.company"
                        defaultMessage="company"
                      >
                        {
                          placeholder => (
                            <Input type="text" name="company" id="company" placeholder={placeholder} required />
                          )
                        }
                      </FormattedMessage>
                    </FormGroup>
                    <FormGroup>
                      <Label className="title">Email*</Label>
                      <Input type="email" name="email" id="email" placeholder="Email" required />
                    </FormGroup>
                    <FormGroup>
                      <Label className="title">
                        <FormattedMessage
                          id="contact.phone"
                          defaultMessage="phone"
                        />
                      </Label>
                      <FormattedMessage
                        id="contact.phone"
                        defaultMessage="phone"
                      >
                        {
                          placeholder => (
                            <Input type="text" name="phone" id="phone" placeholder={placeholder} />
                          )
                        }
                      </FormattedMessage>
                    </FormGroup>
                    <FormGroup>
                      <Label className="title">Message*</Label>
                      <Input
                        type="textarea"
                        name="message"
                        className="message"
                        id="message"
                        placeholder="Message"
                        required
                      />
                    </FormGroup>
                    <Button type="submit" className="positiveButton">
                      <FormattedMessage
                        id="contact.send"
                        defaultMessage="Send"
                      />
                    </Button>
                  </Form>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Container>
    </Layout>
  );
}

export default Contact;